<template>
  <div class="inventory-show">
    <v-container fluid>
      <div class="welcome">
        <v-btn @click.prevent.native="backToDashboard" icon color="blue">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>

        <h1>{{ title }}</h1>
      </div>

      <!-- Do some loading thing here -->
      <div class="loading" v-if="isLoading">
        <v-progress-circular indeterminate color="primary"></v-progress-circular>
      </div>
      <!-- / Do some loading thing here -->

      <!-- Loaded -->
      <v-row v-if="!isLoading && product">
        <v-col cols="12" md="6">
          <product-images-gallery :product="product"></product-images-gallery>
        </v-col>
        <v-col cols="12" md="6">
          <base-card>
            <v-card-title>Bag Details</v-card-title>
            <v-card-text>
              <!-- Description -->
              <!-- <div class="product-property">
                <b>Description</b>
                <br />
                {{ product.description }}
              </div> -->
              <!-- / Description -->
              <!-- Status -->
              <div class="product-property">
                <b>Status</b>
                <br />
                {{ getHumanReadableStatus(product.status) }}
              </div>
              <!-- / Status -->
              <!-- Stock Number -->
              <div class="product-property" v-if="product.stock_number">
                <b>Stock Purchase Number</b>
                <br />
                {{
                  product.stock_number

                }}
              </div>
              <!-- / Stock Number -->
              <!-- Sales Number -->
              <div class="product-property" v-if="product.sale_price">
                <b>Sales Invoice Number</b>
                <br />
                {{
                  product.sales_number

                }}
              </div>
              <!-- / Sales Number -->
              <!-- Country of Export -->
              <div class="product-property" v-if="product.sale_price">
                <b>Country of Export</b>
                <br />
                {{
                  product.country_of_export ? !product.country_of_export : "Not Exported"

                }}
              </div>
              <!-- / Country of Export-->
              <!-- Purchase Price -->
              <div class="product-property">
                <b>Purchase Price</b>
                <br />
                {{
                  formatCostInPence(product.purchase_price, product.currency)
                }}
              </div>
              <!-- / Purchase Price -->
              <!-- Expected Sale Price -->
              <div class="product-property" v-if="product.expected_sale_price && !product.sale_price">
                <b>Expected Sale Price</b>
                <br />
                {{
                  formatCostInPence(
                    product.expected_sale_price,
                  product.currency
                                )
                }}
              </div>
              <!-- / Expected Sale Price -->
              <!-- Sale Price -->
              <div class="product-property" v-if="product.sale_price">
                <b>Sale Price</b>
                <br />
                {{
                  product.sale_price
                    ? formatCostInPence(product.sale_price, product.currency)
                    : "-"
                }}
              </div>
              <!-- / Sale Price -->

               <!-- Sale Price -->
               <div class="product-property" v-if="product.consignor_profit">
                <b>Your Profit</b>
                <br />
                {{
                  product.consignor_profit
                    ? formatCostInPence(product.consignor_profit, product.currency)
                    : "-"
                }}
              </div>
              <!-- / Sale Price -->

              <!-- Location -->
              <div class="product-property">
                <b>Location</b>
                <br />
                {{ product.location ? product.location.name : "-" }}
              </div>
              <!-- / Location -->

              <!-- Purchased At -->
              <div class="product-property">
                <b>Purchased On</b>
                <br />
                {{ product.purchased_at ? formatDate(product.purchased_at) : "-" }}
              </div>
              <!-- / Purchased At -->

              <!-- Sold At -->
              <div class="product-property" v-if="product.sold_at">
                <b>Sold On</b>
                <br />
                {{ product.sold_at ? formatDate(product.sold_at) : "-" }}
              </div>
              <!-- / Sold At -->

              <!-- Sold At -->
              <div class="product-property" v-if="product.sold_at">
                <b>Days Taken To Sell</b>
                <br />
                {{ Math.floor((formatTime(product.sold_at) - formatTime(product.purchased_at)) / 86400000) }}
              <!-- / Sold At -->

              </div>
            </v-card-text>
          </base-card>

          <base-card class="mt-4">
            <v-card-title>Product Details</v-card-title>
            <v-card-text>
              <!-- Style -->
              <div class="product-property">
                <b>Style</b>
                <br />
                {{ product.bag_style ? product.bag_style.description : "-" }}
              </div>
              <!-- / Style -->

              <!-- Condition -->
              <div class="product-property">
                <b>Condition</b>
                <br />
                {{ product.bag_condition ? product.bag_condition.description : "-" }}
              </div>
              <!-- / Condition -->

              <!-- Colour -->
              <div class="product-property">
                <b>Colour</b>
                <br />
                {{
                  product.bag_colour
                    ? product.bag_colour.name +
                    " (" +
                    product.bag_colour.group +
                    ")"
                    : "-"
                }}
              </div>
              <!-- / Colour -->

              <!-- Hardware -->
              <div class="product-property">
                <b>Hardware</b>
                <br />
                {{ product.hardware ? product.hardware.name : "-" }}
              </div>
              <!-- / Hardware -->

              <!-- Skin -->
              <div class="product-property">
                <b>Skin</b>
                <br />
                {{ product.bag_skin ? product.bag_skin.name : "-" }}
              </div>
              <!-- / Skin -->

              <!-- Size -->
              <div class="product-property">
                <b>Size</b>
                <br />
                {{ product.bag_size ? product.bag_size.description : "-" }}
              </div>
              <!-- / Size -->
            </v-card-text>
          </base-card>
        </v-col>
      </v-row>
      <!-- / Loaded -->
    </v-container>
  </div>
</template>

<script>
import products from "../../../../api/products";
import ProductImagesGallery from "../../../../components/gallery/ProductImagesGallery.vue";
import Products from "../../../../mixins/Products";
import Display from "../../../../mixins/Display";
import moment from 'moment';

export default {
  data() {
    return {
      isLoading: false,
      product: null,
    };
  },

  mixins: [Products, Display],

  components: { ProductImagesGallery },

  created() {
    this.loadProduct();
  },

  methods: {
    backToDashboard() {
      this.$router.push("/");
    },

    formatDate(date) {
      return moment(date).format('Do MMMM YYYY');
    },
    formatTime(date) {
      return new Date(date).getTime()
    },

    loadProduct() {
      let vm = this;
      vm.isLoading = true;
      vm.product = null;
      products
        .get(this.productId)
        .then((r) => {
          vm.isLoading = false;
          vm.product = r.data;
        })
        .catch((e) => {
          console.log(e);
          vm.isLoading = false;
          vm.$toast.error("Product not found");
          vm.$router.push("/");
        });
    },
  },

  computed: {
    productId() {
      return this.$route.params.id;
    },

    title() {
      if (this.isLoading) {
        return "Loading...";
      }
      if (this.product) {
        return this.product.name;
      }
      return "Unknown";
    },
  },
};
</script>

<style scoped lang="scss">
.loading {
  text-align: center;
  padding-top: 20px;
}

.inventory-show {
  padding: 20px;

  .welcome {
    margin-bottom: 20px;
    display: flex;
    justify-items: center;

    h1 {
      margin-left: 10px;
    }
  }

  .product-property {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0 !important;
    }
  }
}
</style>

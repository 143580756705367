const _ = require('lodash');

const STATUSES = [
    {
        value: 0,
        label: 'Available to purchase'
    },
    {
        value: 1,
        label: 'Assigned to you - not paid'
    },
    {
        value: 2,
        label: 'Owned by you'
    },
    {
        value: 3,
        label: 'Sold to end client'
    }
];

export default {
    methods: {
        getProductPrimaryImage(product) {
            if (product && product.product_images && product.product_images.length) {
                let img = _.first(product.product_images);
                return img ? window.IMAGE_STORAGE_BASE + '/' + img.image : null;
            }
            return null;
        },

        getProductImageSrc(productImage) {
            return productImage ? window.IMAGE_STORAGE_BASE + '/' + productImage.image : null;
        },

        getHumanReadableStatus(status) {
            let option = _.find(STATUSES, { value: status });
            return option ? option.label : 'Unknown';
        }
    }
}
import axios from 'axios';

export default {
    get(id) {
        return axios.get(window.API_BASE + '/products/' + id);
    },
  getStock(id) {
    return axios.get(window.API_BASE + '/products/stock/' + id);
  },
  searchInvoices(id, params = {}) {
    return axios.post(window.API_BASE + '/products/' + id + '/search-invoices', params);
  }

}
